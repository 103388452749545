<template>
  <div class="cloth-picker">
    
    <div class="top-header">
      <div class="container position-relative">
        <div class="row align-items-center">

          <div class="col-auto position-relative pr-0" v-if="canSearch">
            <button class="search-btn" :class="{'advanced': hasAdvancedFilter}" @click="searchBtnClicked">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.5196 18.997C14.6726 18.997 18.0392 15.6398 18.0392 11.4985C18.0392 7.3572 14.6726 4 10.5196 4C6.36665 4 3 7.3572 3 11.4985C3 15.6398 6.36665 18.997 10.5196 18.997Z" stroke="#8D8D8D" stroke-width="2" stroke-miterlimit="10"/>
                <path d="M17.0996 16.1826L20.856 18.9903" stroke="#8D8D8D" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
              </svg>
            </button>
          </div>

          <div class="col position-relative">
            <ClothCategoryTabs @set="setCategoryFilter"></ClothCategoryTabs>
          </div>
        </div>
        
      </div>
    </div>

    <div class="cloths-main">
      <ClothCardList
        ref="cardList"
        :cloths="cloths"
        :filters="filters"
        :sortType="sortType"
        :sortDirectionDesc="sortDirectionDesc"
        mode="select"
        :selectedIds="selectedClothIds"
        :showTitle="blockShowText"
        @clothClicked="clothClicked">
      ></ClothCardList>

    </div>

    

    <ClothFilterModal v-if="bottomFilterShow" 
      @back="closeFilterModal" 
      @confirm="confirmFilterModal"
      :filterTags="filterTags"
      :filterColors="filterColors"
      :filterPublic="filterPublic"
      :sortType="sortType" 
      :sortDirectionDesc="sortDirectionDesc"
      :showBlockText="blockShowText">
    </ClothFilterModal>
  </div>
</template>

<script>
import ClothCardList from '@/components/clothList/ClothCardList.vue';
import ClothCategoryTabs from '@/components/clothList/ClothCategoryTabs.vue';
import ClothFilterModal from '@/components/clothList/ClothFilterModal.vue';

export default {
  name: 'ClosetPicker',
  data() {
    return {
      tabCategory: -1,

      selectedClothIds: [],

      filterPublic: -1,
      filterTags: [],
      filterColors: [],
      modalFilters: [],
      sortType: 'category',
      sortDirectionDesc: false,
      blockShowText: true,

      bottomFilterShow: false,
    };
  },
  props: {
    cloths: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },
  components: {
    ClothCardList,
    ClothCategoryTabs,
    ClothFilterModal,
  },
  computed: {
    canSearch() {
      return true;
    },
    filters() {
      const filter = [];
      if (this.tabCategory !== -1) {
        filter.push({
          type: 'category1',
          value: this.tabCategory
        });
      }
      if (this.filterTags.length > 0) {
        filter.push({
          type: 'tags',
          value: this.filterTags
        });
      }
      if (this.filterColors.length > 0) {
        filter.push({
          type: 'color',
          value: this.filterColors,
        });
      }
      if (this.filterPublic !== -1) {
        filter.push({
          type: 'isPublic',
          value: this.filterPublic === 1,
        });
      }
      return filter;
    },
    hasAdvancedFilter() {
      for (const f of this.filters) {
        if (f.type !== 'category1') {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    searchBtnClicked() {
      this.bottomFilterShow = true;
    },
    closeFilterModal() {
      this.bottomFilterShow = false;
    },
    confirmFilterModal(filter) {
      // console.log(filter);
      this.filterColors.splice(0, this.filterColors.length);
      for (const c of filter.filterColors) {
        this.filterColors.push(c);
      }
      this.filterTags.splice(0, this.filterTags.length);
      for (const c of filter.filterTags) {
        this.filterTags.push(c);
      }
      this.filterPublic = filter.filterPublic;
      this.sortType = filter.sortType;
      this.sortDirectionDesc = filter.sortDesc;
      this.blockShowText = filter.showBlockText;
      this.closeFilterModal();
    },
    setCategoryFilter(category) {
      this.tabCategory = category.value;
    },
    clothClicked(cloth) {
      const index = this.selectedClothIds.indexOf(cloth.serial);
      if (index === -1) {
        this.selectedClothIds.push(cloth.serial);
      } else {
        this.selectedClothIds.splice(index, 1);
      }
      this.$emit('input', this.selectedClothIds);
    }
  }
}
</script>

<style lang="scss" scoped>
.cloth-picker {
  height: 100%;
  overflow-y: scroll;
  position: relative;
  .top-header {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 2;
    .search-btn {
      border: none;
      background-color: transparent;
      padding: .25rem .5rem;
      &.advanced path {
        stroke: var(--icon-color-highlight);
      }
    }
  }
}
</style>