<template>
  <button @click="btnClicked">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8C11 7.44772 11.4477 7 12 7Z" fill="#404040"/>
    </svg>
    <p>新增抽屜</p>
  </button>
</template>

<script>
export default {
  name: 'DrawerAppendBtn',
  data() {
    return {};
  },
  methods: {
    btnClicked() {
      this.$emit('click');
    },
  }
}
</script>

<style lang="scss" scoped>
button {
  border: dashed 1px var(--border-color-main);
  border-radius: .5rem;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  row-gap: .5rem;
  padding: 1.5rem .5rem;
  p {
    margin: 0;
  }
}
</style>