<template>
  <div class="drawer-list">
    <div class="header">
      <div class="container">
        <div class="header-row">
          <h2>抽屜</h2>
          <span>測試中 | {{ drawerLength }}/{{ drawerLimit }}</span>
          <div class="btn-col">
            <button @click="createDrawerClicked">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8C11 7.44772 11.4477 7 12 7Z" fill="#404040"/>
              </svg>
            </button>
          </div>
        </div>
        <p>利用這個空間將衣櫥整理成你需要的樣子吧！</p>
      </div>
    </div>
    <div class="drawer-contents">
      <div class="container">
        <div class="content-list">
          <div v-for="(item) in drawerWithCloths" :key="item.serial" @click="drawerClicked(item)">
            <DrawerDirectory :drawer="item" :cloths="item.cloths"></DrawerDirectory>
          </div>
          <DrawerAppendBtn @click="createDrawerClicked"></DrawerAppendBtn>
        </div>
      </div>
    </div>
    <WcBottomModal v-if="showDrawerNameInput" :confirmDisabled="createConfirmDisabled" title="新增抽屜" @confirm="createDrawer" @backClick="closeDrawerNameInput" @close="closeDrawerNameInput">
      <div class="container">
        <b-form-input class="py-3 mt-3 mb-4" ref="drawerNameInput" v-model="inputCreateDrawerName" placeholder="輸入抽屜名稱"></b-form-input>
      </div>
    </WcBottomModal>
    <Transition name="fade">
      <DrawerImportModal v-if="showDrawerImportModal" :cloths="importModalCloths" :drawerSerial="importDrawerSerial" @moved="clothMoved" @back="closeImportModal" @skip="closeImportModal">
      </DrawerImportModal>
    </Transition>

    <Transition>
      <DrawerContentModal v-if="showDrawerViewModal" :drawer="viewDrawer" @back="closeViewModal" @deleted="drawerDeleted" @updated="drawerUpdated">
      </DrawerContentModal>
    </Transition>
  </div>
</template>

<script>
import DrawerDirectory from '@/components/drawer/DrawerDirectory';
import DrawerAppendBtn from '@/components/drawer/DrawerAppendBtn';
import DrawerImportModal from '@/components/drawer/DrawerImportModal';
import DrawerContentModal from '@/components/drawer/DrawerContentModal';
import WcBottomModal from '@/components/commons/WcBottomModal';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'ClosetDrawer',
  data() {
    return {
      READBUSY: 'DRAWERREAD',
      SAVEBUSY: 'DRAWERSAVE',
      drawerList: [],

      drawerLimit: 2,

      inputCreateDrawerName: '',
      importDrawerSerial: -1,
      viewDrawerSerial: -1,

      showDrawerNameInput: false,
      showDrawerImportModal: false,
      showDrawerViewModal: false,
    };
  },
  components: {
    DrawerAppendBtn,
    WcBottomModal,
    DrawerDirectory,
    DrawerImportModal,
    DrawerContentModal,
  },
  computed: {
    ...mapState(['cloths']),
    drawerLength() {
      return this.drawerList.length;
    },
    createConfirmDisabled() {
      const t = this.inputCreateDrawerName.trim().length;
      return t === 0 || t > 10;
    },
    importModalCloths() {
      return this.cloths.filter((c) => {
        return !c.isDeleted && c.recycleType === -1 && c.drawerSerial === -1;
      });
    },
    drawerWithCloths() {
      const d = this.drawerList.map((item) => {
        const res = {... item};
        const cloths = this.cloths.filter((c) => {
          return !c.isDeleted && c.recycleType === -1 && c.drawerSerial === res.serial;
        });
        const sorted = cloths.sort((a, b) => {return b.serial - a.serial});
        // if (sorted.length > 5) {
        //   sorted.splice(5);
        // }
        res.cloths = sorted;
        return res;
      });
      return d;
    },
    viewDrawer() {
      return this.drawerList.find((d) => {
        return d.serial === this.viewDrawerSerial;
      });
    },
  },
  async mounted() {
    this.appendComponentBusy(this.READBUSY);
    try {
      const list = await this.$store.dispatch('api/getDrawerListPromise');
      list.map((d) => {
        this.drawerList.push(d);
      });
      const me = await this.$store.dispatch('api/getUserDataPromise');
      this.drawerLimit = me.drawerLimit;
      console.log(me.drawerLimit);
    } catch (error) {
      this.appendErrorMsg(error);
    } finally {
      this.clearComponentBusy(this.READBUSY);
    }
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    drawerClicked(drawer) {
      // this.openImportModal(drawer.serial);
      this.openViewModal(drawer.serial);
    },
    createDrawerClicked() {
      window.trackBtnClicked('btnTryDrawerCreate');
      if (this.drawerList.length < this.drawerLimit) {
        this.openDrawerNameInput();
      } else {
        window.trackBtnClicked('btnTryDrawerCreateButFull');
        this.showMsgModal(`抽屜功能測試中，目前預設一位使用者最多兩個抽屜喔!`);
      }
    },
    openDrawerNameInput() {
      this.inputCreateDrawerName = '';
      this.showDrawerNameInput = true;
      setTimeout(() => {
        this.$refs['drawerNameInput'].focus();
      }, 800);

    },
    closeDrawerNameInput() {
      this.showDrawerNameInput = false;
    },
    async createDrawer() {
      this.appendComponentBusy(this.SAVEBUSY);
      try {
        const createObj = {
          drawerName: this.inputCreateDrawerName.trim(),
        };
        const newDrawer = await this.$store.dispatch('api/postDrawerCreatePromise', createObj);
        this.drawerList.push(newDrawer);
        this.closeDrawerNameInput();
        this.openImportModal(newDrawer.serial);
      } catch (error) {
        this.appendErrorMsg(error);
      } finally {
        this.clearComponentBusy(this.SAVEBUSY);
      }
    },
    openImportModal(drawerSerial) {
      this.importDrawerSerial = drawerSerial;
      this.showDrawerImportModal = true;
    },
    closeImportModal() {
      this.showDrawerImportModal = false;
    },
    clothMoved(cloths) {
      const drawer = this.drawerList.find((d) => {
        return d.serial === this.importDrawerSerial;
      });
      this.closeImportModal();
      this.showMsgModal(`已將 ${cloths.length} 件衣服移入【${drawer.name}】`);
    },
    openViewModal(drawerSerial) {
      this.viewDrawerSerial = drawerSerial;
      this.showDrawerViewModal = true;
    },
    closeViewModal() {
      this.showDrawerViewModal = false;
    },
    drawerDeleted(drawerInfo) {
      this.closeViewModal();
      this.showMsgModal(`已刪除抽屜【${drawerInfo.drawer.name}】`);
      for (let i=0;i<this.drawerList.length;i++) {
        if (this.drawerList[i].serial === drawerInfo.drawer.serial) {
          this.drawerList.splice(i, 1);
          break;
        }
      }
      for (const c of drawerInfo.cloths) {
        this.updateCloth(c);
      }
    },
    drawerUpdated(drawer) {
      for (let i=0;i<this.drawerList.length;i++) {
        if (this.drawerList[i].serial === drawer.serial) {
          this.drawerList.splice(i, 1, drawer);
          break;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-list {
  .header {
    padding: 1rem 0 .5rem;
    .header-row {
      display: flex;
      column-gap: .5rem;
      align-items: center;
      margin-bottom: .75rem;
      >h2 {
        margin: 0;
        font-weight: 800;
        color: var(--icon-color-selection);
      }
      >span {
        color: var(--icon-color-selection);
        background-color: var(--color-brand-primary-20);
        display: block;
        line-height: 1rem;
        padding: .3rem .5rem;
        border-radius: 999px;
        font-size: .8rem;
      }
      .btn-col {
        flex: 1 1;
        text-align: right;
        >button {
          border: none;
          background-color: transparent;
        }
      }
    }

    p {
      margin: 0;
      color: var(--icon-color-selection);
    }
  }
  .drawer-contents {
    .content-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
    }
  }
}
</style>