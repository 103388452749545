<template>
  <div class="drawer-content-modal">
    <FullScreenModal :title="drawer.name" :hideHeader="isSelectMode" @back="backClicked">

      <div class="f">
        <div class="h">
          <div class="manage-nav" v-if="isSelectMode">
            <div class="manage-nav-top">
              <a href="#" @click.prevent="selectAllClicked">全選</a>
      
              <p>整理</p>
      
              <a href="#" @click.prevent="cancelManageBtnClicked">取消</a>
            </div>
            <div class="mange-nav-bottom">
              <p>已選取 {{ selectedClothIds.length }} 件</p>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-between align-items-center py-3">
              <div class="col-auto position-relative pr-0">
                <button class="search-btn" v-if="clothInDrawer.length > 0" :class="{'advanced': hasAdvancedFilter}" @click="searchBtnClicked">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.5196 18.997C14.6726 18.997 18.0392 15.6398 18.0392 11.4985C18.0392 7.3572 14.6726 4 10.5196 4C6.36665 4 3 7.3572 3 11.4985C3 15.6398 6.36665 18.997 10.5196 18.997Z" stroke="#8D8D8D" stroke-width="2" stroke-miterlimit="10"/>
                    <path d="M17.0996 16.1826L20.856 18.9903" stroke="#8D8D8D" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
                  </svg>
                </button>
              </div>

              <div class="col text-right" v-if="isViewMode">
                <a href="#" @click.prevent="openImportModal">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.5 12.5H17.9415C17.2458 12.5 16.8979 12.5 16.636 12.6888C16.3742 12.8775 16.2642 13.2075 16.0442 13.8675L15.9558 14.1325C15.7358 14.7925 15.6258 15.1225 15.364 15.3112C15.1021 15.5 14.7542 15.5 14.0585 15.5H9.94152C9.2458 15.5 8.89794 15.5 8.63605 15.3112C8.37416 15.1225 8.26416 14.7925 8.04415 14.1325L7.95585 13.8675C7.73584 13.2075 7.62584 12.8775 7.36395 12.6888C7.10206 12.5 6.7542 12.5 6.05848 12.5H3.5" stroke="#404040" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.5 9.5L12 12M12 12L14.5 9.5M12 12L12 5" stroke="#404040" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.5 8.5L4.78246 9.64502C4.31358 9.95761 4.07914 10.1139 3.90942 10.3204C3.75917 10.5032 3.64643 10.7139 3.57767 10.9403C3.5 11.196 3.5 11.4778 3.5 12.0413V15.62C3.5 16.6281 3.5 17.1321 3.69619 17.5172C3.86876 17.8559 4.14413 18.1312 4.48282 18.3038C4.86786 18.5 5.37191 18.5 6.38 18.5H17.62C18.6281 18.5 19.1321 18.5 19.5172 18.3038C19.8559 18.1312 20.1312 17.8559 20.3038 17.5172C20.5 17.1321 20.5 16.6281 20.5 15.62V12.0413C20.5 11.4778 20.5 11.196 20.4223 10.9403C20.3536 10.7139 20.2408 10.5032 20.0906 10.3204C19.9209 10.1139 19.6864 9.95761 19.2175 9.64502L17.5 8.5" stroke="#404040" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </a>
              </div>

              <div class="col-auto position-relative">
                共 {{ clothInDrawer.length }} 件
              </div>
            </div>
          </div>
        </div>

        <div class="cloths-list">
          <ClothCardList
            v-if="clothInDrawer.length > 0"
            ref="cardList"
            :cloths="clothInDrawer"
            :filters="filters"
            :sortType="sortType"
            :mode="clothListMode"
            :selectedIds="selectedClothIds"
            :sortDirectionDesc="sortDirectionDesc"
            :showTitle="blockShowText"
            @clothClicked="clothClicked">
          ></ClothCardList>

          <div class="empty-msg" v-if="clothInDrawer.length === 0">
            <p class="text-center">
              還沒有衣服在這個抽屜裡喔！<br/>
              請從總覽中移動單品
            </p>

            <button @click="openImportModal">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5 12.5H17.9415C17.2458 12.5 16.8979 12.5 16.636 12.6888C16.3742 12.8775 16.2642 13.2075 16.0442 13.8675L15.9558 14.1325C15.7358 14.7925 15.6258 15.1225 15.364 15.3112C15.1021 15.5 14.7542 15.5 14.0585 15.5H9.94152C9.2458 15.5 8.89794 15.5 8.63605 15.3112C8.37416 15.1225 8.26416 14.7925 8.04415 14.1325L7.95585 13.8675C7.73584 13.2075 7.62584 12.8775 7.36395 12.6888C7.10206 12.5 6.7542 12.5 6.05848 12.5H3.5" stroke="#404040" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 9.5L12 12M12 12L14.5 9.5M12 12L12 5" stroke="#404040" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.5 8.5L4.78246 9.64502C4.31358 9.95761 4.07914 10.1139 3.90942 10.3204C3.75917 10.5032 3.64643 10.7139 3.57767 10.9403C3.5 11.196 3.5 11.4778 3.5 12.0413V15.62C3.5 16.6281 3.5 17.1321 3.69619 17.5172C3.86876 17.8559 4.14413 18.1312 4.48282 18.3038C4.86786 18.5 5.37191 18.5 6.38 18.5H17.62C18.6281 18.5 19.1321 18.5 19.5172 18.3038C19.8559 18.1312 20.1312 17.8559 20.3038 17.5172C20.5 17.1321 20.5 16.6281 20.5 15.62V12.0413C20.5 11.4778 20.5 11.196 20.4223 10.9403C20.3536 10.7139 20.2408 10.5032 20.0906 10.3204C19.9209 10.1139 19.6864 9.95761 19.2175 9.64502L17.5 8.5" stroke="#404040" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              選取單品
            </button>
          </div>

          <button class="manage-btn" v-if="isViewMode && clothInDrawer.length > 0" @click="changeToSelectMode">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 8.5L4.78246 9.64502C4.31358 9.95761 4.07914 10.1139 3.90942 10.3204C3.75917 10.5032 3.64643 10.7139 3.57767 10.9403C3.5 11.196 3.5 11.4778 3.5 12.0413V15.62C3.5 16.6281 3.5 17.1321 3.69619 17.5172C3.86876 17.8559 4.14413 18.1312 4.48282 18.3038C4.86786 18.5 5.37191 18.5 6.38 18.5H17.62C18.6281 18.5 19.1321 18.5 19.5172 18.3038C19.8559 18.1312 20.1312 17.8559 20.3038 17.5172C20.5 17.1321 20.5 16.6281 20.5 15.62V12.0413C20.5 11.4778 20.5 11.196 20.4223 10.9403C20.3536 10.7139 20.2408 10.5032 20.0906 10.3204C19.9209 10.1139 19.6864 9.95761 19.2175 9.64502L17.5 8.5" stroke="#4D4D4D" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M20.5 12.5H17.9415C17.2458 12.5 16.8979 12.5 16.636 12.6888C16.3742 12.8775 16.2642 13.2075 16.0442 13.8675L15.9558 14.1325C15.7358 14.7925 15.6258 15.1225 15.364 15.3112C15.1021 15.5 14.7542 15.5 14.0585 15.5H9.94152C9.2458 15.5 8.89794 15.5 8.63605 15.3112C8.37416 15.1225 8.26416 14.7925 8.04415 14.1325L7.95585 13.8675C7.73584 13.2075 7.62584 12.8775 7.36395 12.6888C7.10206 12.5 6.7542 12.5 6.05848 12.5H3.5" stroke="#4D4D4D" stroke-width="1.8"/>
              <path d="M9.5 5.5L12 3M12 3L14.5 5.5M12 3L12 10" stroke="#4D4D4D" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <span>移動</span>
          </button>

          <button class="move-to-btn" v-if="isSelectMode" :disabled="selectedClothIds.length === 0" @click="openMoveToModal">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 8.5L4.78246 9.64502C4.31358 9.95761 4.07914 10.1139 3.90942 10.3204C3.75917 10.5032 3.64643 10.7139 3.57767 10.9403C3.5 11.196 3.5 11.4778 3.5 12.0413V15.62C3.5 16.6281 3.5 17.1321 3.69619 17.5172C3.86876 17.8559 4.14413 18.1312 4.48282 18.3038C4.86786 18.5 5.37191 18.5 6.38 18.5H17.62C18.6281 18.5 19.1321 18.5 19.5172 18.3038C19.8559 18.1312 20.1312 17.8559 20.3038 17.5172C20.5 17.1321 20.5 16.6281 20.5 15.62V12.0413C20.5 11.4778 20.5 11.196 20.4223 10.9403C20.3536 10.7139 20.2408 10.5032 20.0906 10.3204C19.9209 10.1139 19.6864 9.95761 19.2175 9.64502L17.5 8.5" stroke="#4D4D4D" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M20.5 12.5H17.9415C17.2458 12.5 16.8979 12.5 16.636 12.6888C16.3742 12.8775 16.2642 13.2075 16.0442 13.8675L15.9558 14.1325C15.7358 14.7925 15.6258 15.1225 15.364 15.3112C15.1021 15.5 14.7542 15.5 14.0585 15.5H9.94152C9.2458 15.5 8.89794 15.5 8.63605 15.3112C8.37416 15.1225 8.26416 14.7925 8.04415 14.1325L7.95585 13.8675C7.73584 13.2075 7.62584 12.8775 7.36395 12.6888C7.10206 12.5 6.7542 12.5 6.05848 12.5H3.5" stroke="#4D4D4D" stroke-width="1.8"/>
              <path d="M9.5 5.5L12 3M12 3L14.5 5.5M12 3L12 10" stroke="#4D4D4D" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <span>移動至</span>
          </button>
        </div>
        
        <ClothFilterModal v-if="bottomFilterShow" 
          @back="closeFilterModal" 
          @confirm="confirmFilterModal"
          :filterTags="filterTags"
          :filterColors="filterColors"
          :filterPublic="filterPublic"
          :sortType="sortType" 
          :sortDirectionDesc="sortDirectionDesc"
          :showBlockText="blockShowText">
        </ClothFilterModal>
      </div>
      <template slot="right-top">
        <button class="opt-btn" @click="openOptModal">
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 22C20 23.1046 20.8954 24 22 24C23.1046 24 24 23.1046 24 22C24 20.8954 23.1046 20 22 20C20.8954 20 20 20.8954 20 22Z" fill="#0D0D0D"/>
            <path d="M20 16C20 17.1046 20.8954 18 22 18C23.1046 18 24 17.1046 24 16C24 14.8954 23.1046 14 22 14C20.8954 14 20 14.8954 20 16Z" fill="#0D0D0D"/>
            <path d="M20 28C20 29.1046 20.8954 30 22 30C23.1046 30 24 29.1046 24 28C24 26.8954 23.1046 26 22 26C20.8954 26 20 26.8954 20 28Z" fill="#0D0D0D"/>
          </svg>
        </button>
      </template>
    </FullScreenModal>
    
    <Transition name="fade">
      <DrawerImportModal v-if="showDrawerImportModal" :cloths="importModalCloths" :drawerSerial="drawer.serial" @moved="clothMoved" @back="closeImportModal" @skip="closeImportModal">
      </DrawerImportModal>
    </Transition>

    <transition name="fade">
      <FullScreenModal v-if="showViewCloth" title="檢視單品" @back="closeViewClothModal">
        <ViewCloth :id="focusClothSerial" @edit="openEditModal" @close="closeViewClothModal" hide-recover-btn></ViewCloth>
      </FullScreenModal>
    </transition>

    <transition name="fade">
      <CreateCloth v-if="showEditCloth" @updated="clothUpdated" :id="focusClothSerial" @back="editClothCancel"></CreateCloth>
    </transition>

    <transition name="fade">
      <WcActionModal v-if="showOptModal" :actions="optModalActions" @cancel="closeOptModal" @action="optAction" hide-cancel></WcActionModal>
    </transition>

    <transition name="fade">
      <WcCommonModal v-if="showDeleteConfirmModal" title="刪除確認" @close="closeDeleteConfirmModal" @cancel="closeDeleteConfirmModal" @backClick="closeDeleteConfirmModal" @ok="deleteDrawer">
        <p>確定要刪除抽屜【{{ drawer.name }}】嗎?</p>
      </WcCommonModal>
    </transition>

    <WcBottomModal v-if="showChangeNameModal" :confirmDisabled="changeNameDisabled" @confirm="editDrawer" @backClick="closeChangeNameModal" @close="closeChangeNameModal">
      <div class="container">
        <div class="row align-items-center py-3">
          <div class="col-auto">
            <p class="m-0">抽屜名稱</p>
          </div>

          <div class="col">
            <CustomTextInput v-model="inputDrawerName" ></CustomTextInput>
          </div>
        </div>
      </div>
    </WcBottomModal>
    
    <DrawerSelectBottomModal v-if="showMoveToModal" :exceptSerials="[drawer.serial]" @close="closeMoveToModal" @confirm="moveCloths"></DrawerSelectBottomModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FullScreenModal from '@/components/FullScreenModal';
import ClothCardList from '@/components/clothList/ClothCardList.vue';
import ClothFilterModal from '@/components/clothList/ClothFilterModal.vue';
import DrawerImportModal from '@/components/drawer/DrawerImportModal';
import ViewCloth from '@/views/closet/ViewCloth.vue';
import CreateCloth from '@/views/closet/CreateCloth.vue';
import DrawerSelectBottomModal from '@/components/drawer/DrawerSelectBottomModal';
import WcActionModal from '@/components/commons/WcActionModal';
import WcCommonModal from '@/components/commons/WcCommonModal';
import CustomTextInput from '@/components/commons/CustomTextInput';
import WcBottomModal from '@/components/commons/WcBottomModal';

export default {
  name: 'DrawerContentModal',
  data() {
    return {
      SAVEBUSY: 'DRAWERCONTENTMODALSAVE',

      filterPublic: -1,
      filterTags: [],
      filterColors: [],
      modalFilters: [],
      selectedClothIds: [],
      sortType: 'category',
      sortDirectionDesc: false,
      blockShowText: false,
      clothListMode: 'view',

      inputDrawerName: '',

      bottomFilterShow: false,
      
      optModalActions: [
        {
          text: '變更抽屜名稱',
          value: 'edit'
        },
        {
          text: '刪除抽屜',
          value: 'delete'
        }
      ],

      showDrawerImportModal: false,
      focusClothSerial: -1,
      showViewCloth: false,
      showEditCloth: false,
      showMoveToModal: false,
      showOptModal: false,
      showDeleteConfirmModal: false,
      showChangeNameModal: false,
    };
  },
  components: {
    FullScreenModal,
    ClothFilterModal,
    ClothCardList,
    DrawerImportModal,
    ViewCloth,
    CreateCloth,
    DrawerSelectBottomModal,
    WcActionModal,
    WcCommonModal,
    CustomTextInput,
    WcBottomModal,
  },
  props: {
    drawer: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapState(['cloths']),
    clothInDrawer() {
      const cs = this.cloths.filter((c) => {
        return !c.isDeleted && c.recycleType === -1 && c.drawerSerial === this.drawer.serial;
      });
      return cs;
    },
    importModalCloths() {
      return this.cloths.filter((c) => {
        return !c.isDeleted && c.recycleType === -1 && c.drawerSerial === -1;
      });
    },
    filters() {
      const filter = [];
      if (this.filterTags.length > 0) {
        filter.push({
          type: 'tags',
          value: this.filterTags
        });
      }
      if (this.filterColors.length > 0) {
        filter.push({
          type: 'color',
          value: this.filterColors,
        });
      }
      if (this.filterPublic !== -1) {
        filter.push({
          type: 'isPublic',
          value: this.filterPublic === 1,
        });
      }
      return filter;
    },
    hasAdvancedFilter() {
      for (const f of this.filters) {
        if (f.type !== 'category1') {
          return true;
        }
      }
      return false;
    },
    isViewMode() {
      return this.clothListMode === 'view';
    },
    isSelectMode() {
      return this.clothListMode === 'select';
    },
    changeNameDisabled() {
      const t = this.inputDrawerName.trim().length;
      return t === 0 || t > 10;
    },
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    backClicked() {
      this.$emit('back');
    },
    searchBtnClicked() {
      this.bottomFilterShow = true;
    },
    closeFilterModal() {
      this.bottomFilterShow = false;
    },
    confirmFilterModal(filter) {
      // console.log(filter);
      this.filterColors.splice(0, this.filterColors.length);
      for (const c of filter.filterColors) {
        this.filterColors.push(c);
      }
      this.filterTags.splice(0, this.filterTags.length);
      for (const c of filter.filterTags) {
        this.filterTags.push(c);
      }
      this.filterPublic = filter.filterPublic;
      this.sortType = filter.sortType;
      this.sortDirectionDesc = filter.sortDesc;
      this.blockShowText = filter.showBlockText;
      this.closeFilterModal();
    },
    openImportModal() {
      this.showDrawerImportModal = true;
    },
    closeImportModal() {
      this.showDrawerImportModal = false;
    },
    clothMoved(cloths) {
      this.closeImportModal();
      this.showMsgModal(`將 ${cloths.length} 件衣服收進抽屜中`);
    },
    changeToSelectMode() {
      this.clothListMode = 'select';
    },
    selectAllClicked() {
      for (const cloth of this.$refs.cardList.sortedCloths) {
        const index = this.selectedClothIds.indexOf(cloth.serial);
        if (index === -1) {
          this.selectedClothIds.push(cloth.serial);
        } else {
          this.selectedClothIds.splice(index, 1);
        }
      }
    },
    cancelManageBtnClicked() {
      if (this.isSelectMode) {
        this.selectedClothIds.splice(0);
        this.clothListMode = 'view';
      }
    },
    clothClicked(cloth) {
      if (this.isSelectMode) {
        const index = this.selectedClothIds.indexOf(cloth.serial);
        if (index === -1) {
          this.selectedClothIds.push(cloth.serial);
        } else {
          this.selectedClothIds.splice(index, 1);
        }
      } else {
        this.focusClothSerial = cloth.serial;
        this.showViewCloth = true;
      }
    },
    closeViewClothModal() {
      this.showViewCloth = false;
    },
    openEditModal() {
      this.closeViewClothModal();
      this.showEditCloth = true;
    },
    clothUpdated(cloth) {
      this.showEditCloth = false;
      this.updateCloth(cloth);
    },
    editClothCancel() {
      this.showEditCloth = false;
      this.showViewCloth = true;
    },
    openMoveToModal() {
      this.showMoveToModal = true;
    },
    closeMoveToModal() {
      this.showMoveToModal = false;
    },

    async moveCloths(drawerSerial) {
      this.appendComponentBusy(this.SAVEBUSY);
      try {
        let list = [];
        if (drawerSerial === -1) {
          list = await this.$store.dispatch('api/postRemoveFromDrawerPromise', this.selectedClothIds);
        } else {
          list = await this.$store.dispatch('api/postDrawerMoveIntoPromise', {
            to: drawerSerial,
            cloths: this.selectedClothIds
          });
        }
        for (const c of list) {
          this.updateCloth(c);
        }
        this.showMsgModal(`已移動 ${this.selectedClothIds.length} 件衣服`);
        this.closeMoveToModal();
        this.cancelManageBtnClicked();
      } catch (error) {
        this.appendErrorMsg(error);
      } finally {
        this.clearComponentBusy(this.SAVEBUSY);
      }
    },
    openOptModal() {
      this.showOptModal = true;
    },
    closeOptModal() {
      this.showOptModal = false;
    },
    optAction(action) {
      if (action === 'delete') {
        this.optDeleteClicked();
      } else if (action === 'edit') {
        this.optEditClicked();
      }
    },
    optDeleteClicked() {
      this.closeOptModal();
      this.openDeleteConfirmModal();
    },
    optEditClicked() {
      this.closeOptModal();
      this.openChangeNameModal();
    },
    openDeleteConfirmModal() {
      this.showDeleteConfirmModal = true;
    },
    closeDeleteConfirmModal() {
      this.showDeleteConfirmModal = false;
    },
    async deleteDrawer() {
      this.appendComponentBusy(this.SAVEBUSY);
      try {
        const drawer = await this.$store.dispatch('api/postDrawerDeletePromise', this.drawer.serial);
        this.closeDeleteConfirmModal();
        this.$emit('deleted', drawer);
      } catch (error) {
        this.appendErrorMsg(error);
      } finally {
        this.clearComponentBusy(this.SAVEBUSY);
      }
    },
    openChangeNameModal() {
      this.inputDrawerName = this.drawer.name;
      this.showChangeNameModal = true;
    },
    closeChangeNameModal() {
      this.showChangeNameModal = false;
    },
    async editDrawer() {
      this.appendComponentBusy(this.SAVEBUSY);
      try {
        const name = this.inputDrawerName.trim();
        const drawer = await this.$store.dispatch('api/postDrawerUpdatePromise', {
          serial: this.drawer.serial,
          name,
        });
        this.$emit('updated', drawer);
        this.closeChangeNameModal();
        this.closeDeleteConfirmModal();
        this.showMsgModal(`已將抽屜更名為【${drawer.name}】`);

      } catch (error) {
        this.appendErrorMsg(error);
      } finally {
        this.clearComponentBusy(this.SAVEBUSY);
      }
      
    },
  }
}
</script>

<style lang="scss" scoped>
.drawer-content-modal {
  .f {
    position: relative;
    .h {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #fff;
      z-index: 2;

      .manage-nav {
        .manage-nav-top {
          display: flex;
          padding: 1rem 1rem;
          justify-content: space-between;
          align-items: center;
          font-weight: 800;
          a {
            &:hover {
              text-decoration: none;
            }
          }

          p {
            color: var(--icon-color-selection);
            font-size: 1.2rem;
          }
        }

        .mange-nav-bottom {
          padding: .5rem;
          text-align: center;
          background: var(--background-color-tertiary);
        }

        p {
          margin: 0;
        }
      }

      .search-btn {
        border: none;
        background-color: transparent;
        padding: .25rem .5rem;
        &.advanced path {
          stroke: var(--icon-color-highlight);
        }
      }
    }

    .cloths-list {
      position: relative;
      .manage-btn {
        position: fixed;
        right: 1rem;
        bottom: 2rem;
        border-radius: 999px;
        border: none;
        background-color: #fff;
        box-shadow: 0px 2px 8px 0px #999;
        padding: .5rem 1rem;
        svg {
          margin-right: .25rem;
        }
      }

      .move-to-btn {
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 2rem;
        border-radius: 999px;
        border: none;
        background-color: #fff;
        box-shadow: 0px 2px 8px 0px #999;
        padding: .5rem 1rem;
        svg {
          margin-right: .25rem;
        }
        &:disabled {
          opacity: .6;
        }
      }
    }

  }

  .empty-msg {
    position: relative;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    button {
      border: solid 1px #E9E9E9;
      border-radius: .5rem;
      background-color: transparent;
      padding: .5rem 1rem;
      svg {
        margin-right: 1rem;
      }
    }
  }

  .opt-btn {
    background-color: transparent;
    padding: 0;
    display: block;
    border: none;
  }
}
</style>