<template>
  <div class="drawer-import-modal">
    <FullScreenModal title="選取單品" @back="backClicked">
      <div class="f">
        <div class="h">
          <div class="container pt-3">
            <h5 class="font-weight-bold">從衣櫥中挑選單品</h5>
          </div>
        </div>
        <div class="picker">
          <ClothPicker
            :cloths="cloths"
            v-model="selectedId"
          ></ClothPicker>
        </div>
        <div class="bottom-div">
          <div class="container">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto">
                已選取 {{ selectedLength }} 件
              </div>
              <div class="col-auto">
                <WcButton color-style="black" :customStyle="{'padding-left': '3rem', 'padding-right': '3rem'}" @click="moveClothsIntoDrawer" :disabled="confirmButtonDisabled">完成</WcButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template slot="right-top">
        <span class="skip-btn">
          <a href="#" @click.prevent="skipClicked">略過</a>
        </span>
      </template>
    </FullScreenModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
// import ClothCardList from '@/components/clothList/ClothCardList.vue';
import FullScreenModal from '@/components/FullScreenModal';
import WcButton from '@/components/commons/WcButton';
import ClothPicker from '@/components/clothList/ClothPicker';
export default {
  name: 'DrawerImportModal',
  data() {
    return {
      SAVEBUSY: 'DRAWERIMPORTSAVE',
      
      selectedId: [],
    };
  },
  components: {
    FullScreenModal,
    ClothPicker,
    WcButton,
    // ClothCardList,
  },
  props: {
    cloths: {
      type: Array,
      required: true,
    },
    drawerSerial: {
      type: Number,
      required: true,
    }
  },
  computed: {
    selectedLength() {
      return this.selectedId.length;
    },
    confirmButtonDisabled() {
      return this.selectedLength === 0;
    }
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    backClicked() {
      this.$emit('back');
    },
    skipClicked() {
      this.$emit('skip');
    },
    async moveClothsIntoDrawer() {
      this.appendComponentBusy(this.SAVEBUSY);
      try {
        const list = await this.$store.dispatch('api/postDrawerMoveIntoPromise', {
          to: this.drawerSerial,
          cloths: this.selectedId,
        });
        for (const c of list) {
          console.log(c);
          this.updateCloth(c);
        }
        this.$emit('moved', list);
      } catch (error) {
        this.appendErrorMsg(error);
      } finally {
        this.clearComponentBusy(this.SAVEBUSY);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-import-modal {
  .f {
    display: flex;
    flex-direction: column;
    height: 100%;

    .h {
      flex: 0 0 auto;
    }

    .picker {
      flex: 1 1;
      overflow-y: hidden;
      ::v-deep .cloths-main {
        padding-bottom: 6rem;
      }
    }

    .bottom-div {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #fff;
      padding: 1.5rem .5rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      box-shadow: 0px 0px 1rem 0px #999;
    }
  }

  .skip-btn {
    display: block;
    padding: .7rem .5rem;
    >a {
      color: var(--text-color-remark);
    }
  }
}
</style>