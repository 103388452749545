<template>
  <div class="drawer-select-bottom-modal">
    <WcBottomModal :confirmDisabled="selectedSerial === null" @backClick="closeModal" @close="closeModal" @confirm="confirmed">
      <div class="container">
        <VerticalList
          :items="drawerNames"
          v-model="selectIndex"
        ></VerticalList>
      </div>
    </WcBottomModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import WcBottomModal from '@/components/commons/WcBottomModal';
import VerticalList from '@/components/commons/VerticalList';

export default {
  name: 'DrawerSelectBottomModal',
  data() {
    return {
      READBUSY: 'DRAWERSELECTBOTTOMMODALREAD',

      list: [],
      selectIndex: -1,
    };
  },
  computed: {
    drawerSets() {
      const res = [];
      res.push({
        serial: -1,
        name: '移出所有抽屜'
      });
      for (let i=0;i<this.list.length;i++) {
        if ( !this.exceptSerials.includes(this.list[i].serial) ) {
          res.push({
            serial: this.list[i].serial,
            name: this.list[i].name
          });
        }
      }
      return res;
    },
    drawerNames() {
      const res = [];
      for (let i=0;i<this.drawerSets.length;i++) {
        res.push(this.drawerSets[i].name);
      }
      return res;
    },
    selectedSerial() {
      if (this.selectIndex >=0 && this.selectIndex < this.drawerSets.length) {
        return this.drawerSets[this.selectIndex].serial;
      }
      return null;
    },
  },
  props: {
    exceptSerials: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },
  components: {
    WcBottomModal,
    VerticalList,
  },
  async created() {
    this.appendComponentBusy(this.READBUSY);
    try {
      const list = await this.$store.dispatch('api/getDrawerListPromise');
      list.map((d) => {
        this.list.push(d);
      });
    } catch (error) {
      this.appendErrorMsg(error);
    } finally {
      this.clearComponentBusy(this.READBUSY);
    }
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    closeModal() {
      this.$emit('close');
    },
    confirmed() {
      this.$emit('confirm', this.selectedSerial);
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-select-bottom-modal {

}
</style>