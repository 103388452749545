<template>
  <button @click="btnClicked" :style="buttonStyle" :disabled="disabled">
    <div>
      <slot></slot>

    </div>
  </button>
</template>

<script>

export default {
  name: 'WcButton',
  data() {
    return {
    }
  },
  components: {
  },
  props: {
    colorStyle: {
      type: String,
      required: false,
      default: "regular"
    },
    customStyle: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    buttonStyle() {
      const s = {};
      switch(this.colorStyle) {
        case 'black':
          s.backgroundColor = '#404040';
          s.color = '#fff';
          break;
      }
      const final = Object.assign({}, s, this.customStyle);
      return final;
    },
  },
  methods: {
    btnClicked() {
      this.$emit('btnClick');
      this.$emit('click');
    },
  }

}
</script>

<style lang="scss" scoped>
button {
  display: block;
  border: none;
  width: 100%;
  border-radius: .5rem;
  background-color: #E3861A;
  color: #fff;
  padding: .5rem 1.5rem;
  &:disabled {
    opacity: .6;
  }
  >div {
    text-align: center;
  }
}
</style>
