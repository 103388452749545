<template>
  <div class="drawer-directory mb-3">
    <div class="rs">
      <div class="imgs">
        <img class="imgs-1" :src="cloths[0].imgs[0]" v-if="cloths.length > 0" />
        <img class="imgs-2" :src="cloths[1].imgs[0]" v-if="cloths.length > 1" />
        <img class="imgs-3" :src="cloths[2].imgs[0]" v-if="cloths.length > 2" />
      </div>
    </div>

    <div class="mt-2">
      <div class="row no-gutters justify-content-between align-items-center">
        <p class="mb-0">
          {{ drawerName }}
        </p>

        <p class="mb-0">
          {{ cloths.length }} 件
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DrawerDirectory',
  data() {
    return {

    };
  },
  props: {
    drawer: {
      type: Object,
      required: true,
    },
    cloths: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    }
  },
  computed: {
    drawerName() {
      return this.drawer.name;
    },
  }
}
</script>

<style lang="scss" scoped>
.drawer-directory {
  position: relative;
  .rs {
    position: relative;
    padding-top: 66.67%;
    border-radius: .5rem;
    background-color: #F7F7F7;
    .imgs {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-areas:
        'img1 img1 img2'
        'img1 img1 img3';
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 50% 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        &.imgs-1 {
          grid-area: img1;
        }
        &.imgs-2 {
          grid-area: img2;
        }
        &.imgs-3 {
          grid-area: img3;
        }
      }
    }
  }
}
</style>